export const environment = {
  production: true,
  svAPIKey: "OLU321",
  baseUrl: "https://production.public.api.lafka.dev/api/v1",
  googleAppId: "",
  facebookAppId: "",
  firebase: {
    apiKey: "AIzaSyCRaazBIp69xn5NtDGOJqRZ4y6AI95mY5I",
    authDomain: "olutmaailma-prod.firebaseapp.com",
    projectId: "olutmaailma-prod",
    storageBucket: "olutmaailma-prod.appspot.com",
    messagingSenderId: "855903432537",
    appId: "1:855903432537:web:5f78477045f1d80e66a648",
    measurementId: "G-RBZY6SCKJY",
  },
};
